export enum ActionType {
  REFERRAL = "REFERRAL",
  PAGE_VIEW = "PAGE_VIEW",
  SEARCH = "SEARCH",
}

export const sendEvent = (action: ActionType, voltwise_id?: string) => {
  fetch("/api/event", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: action,
      voltwise_id: voltwise_id,
    }),
  });
};
