import { useState, useEffect } from "react";
import { Heading, LinkBox, LinkOverlay, Text } from "@chakra-ui/react";
import styles from "./styles.module.css"; // Import CSS module
import BoxComponent from "../BoxComponent";
import { tradingNameParser } from "src/lib/utils";
import ServicesIcons from "../ServicesIcons";
import StarDisplay from "../StarDisplay";

type InstallerCardItemProps = {
  title: string;
  content: string;
  installer: any;
};

const InstallerCardItem = ({
  title,
  content,
  installer,
}: InstallerCardItemProps) => {
  const reviewScore = installer?.google_profiles?.google_review_score || null;

  return (
    <div className={styles.card}>
      <BoxComponent>
        <LinkBox>
          <LinkOverlay href={`/installers/${installer.slug}`}>
            {reviewScore && <StarDisplay stars={reviewScore} />}
            <br />
            <Heading
              as={"h3"}
              fontSize={18}
              mt={-2}
              mb={-2}
              color="#0B3948"
              size="md"
            >
              {tradingNameParser(title)}
            </Heading>
            <Text mb={6} fontWeight={"normal"} color={"gray.400"}>
              {content}
            </Text>
            <ServicesIcons showServiceName={false} {...installer} />
          </LinkOverlay>
        </LinkBox>
      </BoxComponent>
    </div>
  );
};

// TODO: You've abstracted <BoxComponent> - this can be used elsewhere (e.g. search result, installer card, etc.)

const CardCarousel = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/top-reviews"); // Fetch data from API route
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCards(data); // Update state with fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className={styles.section}>
        <>
          <div className={styles.container}></div>
          <div className={styles.containerWrapper}>
            <div className={styles.sectionCarousel}>
              <div className={styles.testimonialCarouselContainer}>
                <div className={styles.testimonialCarouselWrapL}>
                  <div className={styles.testimonialCarouselLeft}>
                    {cards.map((card, index) => (
                      <InstallerCardItem
                        key={index}
                        title={card.name}
                        content={card.county}
                        installer={card}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        <>
          <div className={styles.container}></div>
          <div className={styles.containerWrapper}>
            <div className={styles.sectionCarousel}>
              <div className={styles.testimonialCarouselContainer}>
                <div className={styles.testimonialCarouselWrapR}>
                  <div className={styles.testimonialCarouselRight}>
                    {cards.map((card, index) => (
                      <InstallerCardItem
                        key={index}
                        title={card.name}
                        content={card.county}
                        installer={card}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default CardCarousel;
