import { HStack, Stack, Text, Wrap, WrapItem } from "@chakra-ui/react";
import SVGIcon from "./SVGIcon";
import { InstallerServicesList } from "src/lib/utils";

const InstallerServices = ({ showServiceName, ...props }) => {
  return (
    <>
      <Stack direction={["column"]} spacing={0}>
        <Wrap>
          {InstallerServicesList.map(
            (service) =>
              props[service.key] && (
                <WrapItem key={service.key}>
                  <HStack mr={2}>
                    <SVGIcon icon={service.file} size="20px" />
                    {showServiceName && (
                      <Text fontWeight="normal" color={"gray.500"} pr={5}>
                        {service.name}
                      </Text>
                    )}
                  </HStack>
                </WrapItem>
              )
          )}
        </Wrap>
      </Stack>
    </>
  );
};

export default InstallerServices;
