import CardCarousel from "../components/ScrollingList/ScrollingList";
import Hero from "../components/Hero";

export default function Home() {
  return (
    <>
      <Hero />
      <CardCarousel />
    </>
  );
}
