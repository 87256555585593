import { StarIcon } from "@chakra-ui/icons";
import { HStack } from "@chakra-ui/react";

export default function StarDisplay({ stars }) {
  return (
    <HStack spacing={0}>
      <StarIcon color={stars != 0 ? "green.400" : "gray.200"} />
      <StarIcon color={stars > 1 ? "green.400" : "gray.200"} />
      <StarIcon color={stars > 2 ? "green.400" : "gray.200"} />
      <StarIcon color={stars > 3 ? "green.400" : "gray.200"} />
      <StarIcon color={stars > 4 ? "green.400" : "gray.200"} />
    </HStack>
  );
}
