import axios from "axios";

export const slugify = (string) => {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "")
    .replace(/t\/a/g, "ta");
};

export const countReviews = (installers) => {
  let count = 0;
  installers.forEach((installer) => {
    count += installer.google_review_count;
  });

  //convert to string and add commas
  return count;
};

export const websiteParser = (url) => {
  let parsedUrl = url;

  if (url) {
    // https://stackoverflow.com/questions/8206269/how-to-remove-http-from-a-url-in-javascript
    parsedUrl = url.replace(/^\/\/|^.*?:(\/\/)?/, "");
  }
  return parsedUrl.toLowerCase();
};

export const tradingNameParser = (name) => {
  let parsedName = name;
  const index = name.toUpperCase().indexOf("T/A");
  if (index > -1) {
    parsedName = name.substring(index + 3, name.length);
  }

  // trim any whitespace
  parsedName = parsedName.trim();
  return parsedName;
};

export function prepareForSerialization(obj) {
  return obj.mapValues(obj, (value) =>
    typeof value === "undefined" ? null : value
  );
}

export function getYearFromCompaniesHouseDate(date) {
  if (date) {
    return "Founded: " + date.substring(0, 4);
  } else {
    return null;
  }
}

export async function getPostCodeLatLong(postcode) {
  try {
    const response = await axios.get(
      `https://api.postcodes.io/postcodes/${postcode}`
    );
    const { latitude, longitude, ...locationData } = response.data.result;
    return [latitude, longitude, locationData];
  } catch (error) {
    throw error;
  }
}

export const InstallerServicesList = [
  {
    name: "Solar PV",
    key: "technology_solarpv",
    file: "/sun.svg",
  },
  {
    name: "Air Source Heat Pump",
    key: "technology_ashp",
    file: "/heat.svg",
  },
  {
    name: "Biomass",
    key: "technology_biomass",
    file: "/fire.svg",
  },
  {
    name: "Exhaust Air Heat Pump",
    key: "technology_eahp",
    file: "/heat.svg",
  },
  {
    name: "Ground Air Heat Pump",
    key: "technology_gahp",
    file: "/heat.svg",
  },
  {
    name: "Ground Source Heat Pump",
    key: "technology_gshp",
    file: "/heat.svg",
  },
  {
    name: "Hydro",
    key: "technology_hydro",
    file: "/water.svg",
  },
  {
    name: "Micro-CHP",
    key: "technology_microchp",
    file: "/heat.svg",
  },
  {
    name: "Surface Air Heat Pump",
    key: "technology_sahp",
    file: "/heat.svg",
  },
  {
    name: "Solar Thermal",
    key: "technology_solarthermal",
    file: "/sun.svg",
  },
  {
    name: "Wind Turbine",
    key: "technology_windturbine",
    file: "/wind.svg",
  },
  {
    name: "Water Source Heat Pump",
    key: "technology_wshp",
    file: "/heat.svg",
  },
  {
    name: "Battery Storage",
    key: "technology_batterystorage",
    file: "/battery.svg",
  },
];
