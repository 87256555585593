import { SearchIcon } from "@chakra-ui/icons";
import { postcodeValidator } from "postcode-validator";
import { useRouter } from "next/router";

import {
  Button,
  Input,
  FormControl,
  FormErrorMessage,
  Stack,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { sendEvent, ActionType } from "src/lib/sendEvent";

export default function PostcodeField(props) {
  const { heroVariant, queryValue } = props;
  const router = useRouter();

  function validatePostcode(value) {
    let error;
    if (!value) {
      error = null;
    } else if (!postcodeValidator(value, "GB")) {
      error = "Check your postcode";
    }
    return error;
  }

  function submit(values) {
    router.push({
      pathname: "/installers",
      query: {
        postcode: values.postcode.toUpperCase(),
        technology_ashp: true,
        technology_solarpv: true,
        distance: 50,
        sortBy: "rating",
      },
    });
    sendEvent(ActionType.SEARCH);
  }

  return (
    <Formik
      initialValues={{ postcode: queryValue ? queryValue : "" }}
      onSubmit={(values) => submit(values)}
    >
      {(props) => (
        <Form>
          <Stack
            direction={["column", "row"]}
            align={"top"}
            alignSelf={"center"}
            maxWidth={"400px"}
            mx={heroVariant ? "auto" : 0}
          >
            <Field name="postcode" validate={validatePostcode}>
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.postcode && form.touched.postcode}
                >
                  <Input
                    {...field}
                    name="postcode"
                    placeholder="Enter your postcode"
                    maxWidth={[600, 300]}
                    value={field.value.toUpperCase()}
                    bg="white"
                    autoFocus
                  />
                  <FormErrorMessage>{form.errors.postcode}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button
              type="submit"
              isLoading={props.isSubmitting}
              disabled={props.values.postcode.length < 1 || !props.isValid}
              colorScheme={"green"}
              leftIcon={<SearchIcon />}
              bg={"green.400"}
              px={6}
              _hover={{
                bg: "green.500",
              }}
            >
              Go
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
