import { Box } from "@chakra-ui/react";

const BoxComponent = ({ children }) => {
  return (
    <Box
      h="100%"
      minW={"400px"}
      bg={"white"}
      borderWidth="0.5px"
      p={8}
      borderRadius={4}
      m={4}
      boxShadow={
        "rgba(0, 0, 0, 0.03) 0px 6px 24px 0px, rgba(0, 0, 0, 0.04) 0px 0px 0px 1px;"
      }
      whiteSpace="pre-wrap"
    >
      {children}
    </Box>
  );
};

export default BoxComponent;
