import { Box, Heading, Stack, Text, Image } from "@chakra-ui/react";
import PostcodeField from "./PostcodeField";

const ImageCard = ({ src, alt, rotationAngle, verticalOffset = 0 }) => {
  return (
    <Box
      bg="white"
      p={4}
      borderRadius="md"
      boxShadow="md"
      overflow="hidden"
      border={"0.5px lightgrey solid"}
      position="relative" // Add position relative
      top={verticalOffset} // Adjust vertical position
      transform={`rotate(${rotationAngle}deg)`} // Rotate the box
      zIndex={1} // Ensure the box is above other content
    >
      <Image
        src={src}
        alt={alt}
        height={[150, 200]}
        borderRadius="sm"
        width={[200, 200]}
        placeholder="empty"
        position="relative" // Add position relative to ensure drop shadow is visible all around
        objectFit="cover" // Avoid stretching the image
      />
    </Box>
  );
};

const OverLappingPhotos = () => {
  return (
    <Stack
      direction="row" // Display images in a row
      spacing={-8} // Adjust spacing between images
      align="center"
      marginBottom={10}
    >
      <ImageCard src="/photo_1.jpg" alt="Photo 1" rotationAngle={-5} />
      <ImageCard
        src="/photo_2.jpg"
        alt="Photo 2"
        rotationAngle={4}
        verticalOffset={3}
      />
    </Stack>
  );
};

export default function Hero() {
  return (
    <Box padding={["10%", "0%"]}>
      <Stack
        direction={["column", "row"]}
        spacing={8}
        justify="center"
        align="center"
      >
        <OverLappingPhotos />
      </Stack>

      <Stack textAlign={"center"} maxW={"800px"} ml={"auto"} mr={"auto"} mt={8}>
        <Heading
          as="h1"
          fontWeight={750}
          fontSize={["3xl", "4xl"]}
          lineHeight={"110%"}
          mb={4}
          color={"0B3948"}
        >
          Find trusted, {"  "}
          <Text as={"span"} color={"green.400"}>
            local installers{" "}
          </Text>
          of solar panels, heat pumps, and EV chargers in the UK
        </Heading>
        <br />
        <PostcodeField heroVariant />
      </Stack>
    </Box>
  );
}
